.App {
  height: 100vh;
  text-align: center;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 50px);
}

.bots {
  display: flex;
  gap: 32px;
}

h1 {
  font-size: 3em;
  line-height: 0px;
}
h2 {
  font-size: 2em;
}

.item {
  background-color: #1f2229;
  padding: 25px;
  border-radius: 25px;
}
.img {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}
.info h3 {
  font-size: 1.5em;
}
.info p {
  width: 30ch;
  color: gray;
}
.invite button {
  cursor: pointer;
  color: #282c34;
  background: #61dafb;
  border: none;
  outline: none;
  padding: 5px 10px;
  font-size: 1.3em;
  border-radius: 10px;
}

img {height: 100%; width: 100%;}

footer {
  height: 50px;
}
footer a {
  color: #61dafb;
  text-decoration: none;
}